var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-card',{staticClass:"box-card"},[_c('vab-query-form-left-panel',{attrs:{"span":24}},[_c('el-radio-group',{on:{"change":_vm.buttonTab},model:{value:(_vm.queryForm.type),callback:function ($$v) {_vm.$set(_vm.queryForm, "type", $$v)},expression:"queryForm.type"}},_vm._l((_vm.button_state),function(item){return _c('el-radio-button',{key:item.id,attrs:{"type":"button","label":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1),_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.listLoading),expression:"listLoading"}],attrs:{"data":_vm.list},on:{"selection-change":_vm.setSelectRows}},[_c('el-table-column',{attrs:{"align":"center","prop":"user_info","label":"会员"}}),_c('el-table-column',{attrs:{"align":"center","label":"参与产品","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_vm._v(_vm._s(row.sku_info.name))])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"cashback.name","label":"参与活动"}}),_c('el-table-column',{attrs:{"align":"center","prop":"is_reach","label":"是否达标"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_reach == 1)?_c('span',[_vm._v("是")]):_c('span',[_vm._v("否")])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"is_cashback","label":"是否返现"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.is_cashback == 1)?_c('span',[_vm._v("是")]):_c('span',[_vm._v("否")])]}}])}),_c('el-table-column',{attrs:{"align":"center","prop":"completion","label":"最新完成情况"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.completion)?_c('span',[_vm._v(_vm._s(_vm.completion))]):_c('span',[_vm._v("未知")])]}}])}),_c('el-table-column',{attrs:{"align":"center","label":"操作","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleEdit(row)}}},[_vm._v("查看明细")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleFreeze(row)}}},[_vm._v("冻结资格")]),_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleBack(row)}}},[_vm._v("执行返现")])]}}])})],1),_c('el-pagination',{attrs:{"background":"","current-page":_vm.queryForm.page,"layout":_vm.layout,"align":"center","page-size":_vm.queryForm.pageSize,"total":_vm.count},on:{"current-change":_vm.handleCurrentChange,"size-change":_vm.handleSizeChange}}),_c('Edit',{ref:"Edit"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
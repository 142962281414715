<template>
  <el-card class="box-card">
    <vab-query-form-left-panel :span="24">
      <el-radio-group v-model="queryForm.type" @change="buttonTab">
        <el-radio-button type="button"
                         v-for="item in button_state"
                         :key="item.id"
                         :label="item.id"
        >
          {{ item.name }}
        </el-radio-button>
      </el-radio-group>
    </vab-query-form-left-panel>

    <el-table
        v-loading="listLoading"
        :data="list"
        @selection-change="setSelectRows">
      <el-table-column
          align="center"
          prop="user_info"
          label="会员">
      </el-table-column>
      <el-table-column align="center" label="参与产品" show-overflow-tooltip>
        <template #default="{ row }">
          <div>{{ row.sku_info.name }}</div>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="cashback.name"
          label="参与活动">
      </el-table-column>
      <el-table-column
          align="center"
          prop="is_reach"
          label="是否达标">
        <template #default="{ row }">
          <span v-if="row.is_reach == 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="is_cashback"
          label="是否返现">
        <template #default="{ row }">
          <span v-if="row.is_cashback == 1">是</span>
          <span v-else>否</span>
        </template>
      </el-table-column>
      <el-table-column
          align="center"
          prop="completion"
          label="最新完成情况">
        <template #default="{ row }">
          <span v-if="row.completion">{{ completion }}</span>
          <span v-else>未知</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="250">
        <template #default="{ row }">
          <el-button type="text" @click="handleEdit(row)">查看明细</el-button>
          <el-button type="text" @click="handleFreeze(row)">冻结资格</el-button>
          <el-button type="text" @click="handleBack(row)">执行返现</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        background
        :current-page="queryForm.page"
        :layout="layout"
        align="center"
        :page-size="queryForm.pageSize"
        :total="count"
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
    />
    <Edit ref="Edit"/>
  </el-card>
</template>
<script>
import {formatDate} from "@/utils/theTimeStamp";
import Edit from './components/Edit'
import {apiGetPaymentUsers, apiOperationCashbackState, apiOperationFreezeState} from "@/request/api";

export default {
  // 时间戳转换
  filters: {
    formatDate(time) {
      time = time * 1000
      let date = new Date(time)
      return formatDate(date, 'yyyy-MM-dd hh:mm')
    },
  },
  components: {Edit},
  data() {
    return {
      selectRows: '',
      listLoading: false,
      layout: 'total, sizes, prev, pager, next, jumper',
      count: 0,
      queryForm: {
        page: 1,
        pageSize: 10,
        id: this.$route.query.id,
        type: 0,
      },
      button_state: [
        {
          id: 0,
          name: '全部'
        },
        {
          id: 1,
          name: '达标'
        },
        {
          id: 2,
          name: '未达标'
        },
      ],
      list: [],
    }
  },
  created() {
    this.fetchData()
  },
  mounted() {
  },
  methods: {
    // tab切换
    buttonTab(num) {
      if (num == 1) {
        this.queryForm.type = 1
      } else if (num == 2) {
        this.queryForm.type = 2
      } else {
        this.queryForm.type = 0
      }
      this.fetchData()
    },
    //调起弹窗查看详情
    handleEdit(row) {
      if (row.id) {
        this.$refs['Edit'].showEdit(row)
      } else {
        this.$message.error('参数错误')
      }
    },
    //冻结资质
    handleFreeze(row) {
      apiOperationFreezeState({id: row.id}).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //执行返现
    handleBack(row) {
      apiOperationCashbackState({id: row.id}).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    //分页
    setSelectRows(val) {
      this.selectRows = val
    },
    handleSizeChange(val) {
      this.queryForm.pageSize = val
      this.fetchData()
    },
    handleCurrentChange(val) {
      this.queryForm.page = val
      this.fetchData()
    },
    //获取列表数据
    async fetchData() {
      this.listLoading = true
      apiGetPaymentUsers(this.queryForm).then(res => {
        if (res.code == 200) {
          this.list = res.data.list
          this.count = res.data.count
        }
      })
      this.listLoading = false
    },
  }
}

</script>
